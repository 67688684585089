import inspiru from "@/api/inspiru";
import axios from "axios";
import cookies from 'vue-cookies'
import i18n from '@/i18n/index.js'
import dayjs from 'dayjs';
// import toast from "@/plugins/toast";

const currency_table = require("@/data/currencies.json");

export default {
  namespaced: true,
  state() {
    return {
      user: null,
      error: null,
      isLoggedIn: null,
      locale: null,
      currency: null,
      language: null,
      country: null,
      userClinic: null,
      activeResource: null,
    };
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setLocale(state, locale) {
      state.locale = locale;
    },
    setCurrency(state, currency) {
      state.currency = currency;
    },
    setLanguage(state, language) {
      state.language = language;
    },
    setCountry(state, country) {
      state.country = country;
    },
    setUserClinic(state, userClinic) {
      state.userClinic = userClinic;
    },
    updateResource(state, resource) {
      state.userClinic.users = state.userClinic.users.map(r => {
        if (r._id === resource._id) {
          return resource
        } else {
          return r
        }
      })
    },
    setActiveResource(state, resource) {
      state.activeResource = resource;
    },
    setError(state, error) {
      state.error = error;
    },
    clearError(state) {
      state.error = null;
    },
  },
  actions: {
    setLocale() {

      const langCookie = cookies.get('lang');
      if (langCookie) {
        i18n.locale = langCookie;
      }
      const languageCode = i18n.locale.split('-')[0];
      dayjs.locale(languageCode);

    },
    setCurrencyByCountry({ commit }, country) {
      const cntry = currency_table.find(c => c.CountryCode === country)
      let currency = cntry?.Code
      commit('setCurrency', currency || 'DKK')
      commit('setCountry', country || 'DK')
      return currency
    },
    setCurrency({ commit }, currency) {
      commit('setCurrency', currency)
    },
    setLanguage({ commit }, language) {
      commit('setLanguage', language || 'da-DK')
    },
    setActiveResource({ commit }, resource) {
      commit('setActiveResource', resource)
    },
    setUserClinic({ commit }, userClinic) {
      commit('setUserClinic', userClinic)
    },

    /**
     * Fetch the user object for the currently-logged-in user and populate the store.
     * 
     * @param {*} param0 
     */
    async initUser({ dispatch, getters }) {
      if (getters.isLoggedIn === null) {
        const response = await axios.get('/auth/user');
        if (response.data.user) {
          await dispatch('login', response.data.user);
          return response.data.user
        } else {
          await dispatch('logout');
        }
      }
    },

    /**
     * Fetch the clinic object for the given user and populate the store.
     * 
     * @param {*} param0 
     * @param {*} user 
     */
    async initUserClinic({ commit, dispatch }, user) {
      commit('clearError');
      try {
        const clinicId = user.access?.clinics?.[0];

        if (clinicId) {
          const userClinic = await inspiru.getFullClinicPrivate(clinicId);

          if (!userClinic || Object.keys(userClinic).length === 0 || !Array.isArray(userClinic.users)) {
            throw new Error('This user is associated with a clinic that no longer exists or has no users.');
          }

          const resource = userClinic.users.find(r => r._id === user._id);
          if (!resource) {
            throw new Error('User not found in the clinic\'s users.');
          }

          dispatch('setActiveResource', resource);
          dispatch('calendar/setActiveResource', resource, { root: true });
          commit('setUserClinic', userClinic);
          await dispatch('setCurrency', userClinic?.address?.country);
          await dispatch('setLanguage', userClinic?.locale?.language);
        } else if (user.access.status !== 'clinic_new') {
          throw new Error('There is no clinic associated with this user.');
        }
      } catch (error) {
        commit('setError', error.message);
        return error.message;
      }
    },

    async reloadUserClinic({ commit, dispatch }, clinic) {
      try {
        let userClinic = await inspiru.getFullClinicPrivate(clinic._id);
        commit('setUserClinic', userClinic);
        await dispatch('setCurrency', userClinic?.address?.country);
        await dispatch('setLanguage', userClinic?.locale?.language);
      } catch (error) {
        commit('setError', error.message);
        return error.message;
      }
    },


    logout({ commit }) {
      commit('setUser', null);
      commit('setIsLoggedIn', false);
      commit('setUserClinic', null);
      cookies.remove('os_token')
    },

    /**
     * Populate the VueX store with data from the given user object.
     * 
     * @param {*} param0 
     * @param {*} user 
     * @returns 
     */
    async login({ commit, dispatch }, user) {
      commit('setUser', user);
      commit('setIsLoggedIn', true);
      return await dispatch('initUserClinic', user);
    },
  },

  getters: {
    user(state) {
      return state.user;
    },
    userStatus(state) {
      return state.user?.access?.status;
    },
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    isSuperAdmin(state) {
      return state.user?.access?.superadmin || false;
    },
    locale(state) {
      return state.locale;
    },
    currency(state) {
      return state.currency;
    },
    language(state) {
      return state.language;
    },
    country(state) {
      return state.country;
    },
    userClinic(state) {
      return state.userClinic;
    },
    activeResource(state) {
      return state.activeResource;
    },
    error(state) {
      return state.error;
    }
  }
}

<template>
  <component
    :is="component"
    v-for="(resource, index) in resources"
    :key="index"
    :selected="resource._id === id"
    :name="resource.name"
    :size="size"
    :src="resource.imageUrl"
    @click="selectResource(resource._id)"
  />
</template>

<script>
import AvaTar from '@/components/assets/AvaTar.vue'
import AvatarName from '@/components/assets/AvatarName.vue'
export default {
  components: { AvaTar, AvatarName },
  data() {
    return {
      component: null,
    };
  },
  props: ["column", "size", "names"],
  computed: {
    resource() {
      return this.$store.getters['calendar/activeResource']
    },
    id() {
      return this.resource?._id
    },
    resources() {
      return this.$store.getters['auth/userClinic']?.users;
    }
 
  },

  methods: {
    async selectResource(id) {
      const resource = this.resources.find((item) => {
        return item._id === id;
      });
      await this.$store.dispatch('calendar/setActiveResource', resource)
    }
  },
  async mounted() {
    this.component = this.names ? "AvatarName" : "AvaTar"
  },
};
</script>

<style lang="sass" scoped>
@media only screen and (max-width: 767px)
</style>

import store from '@/store/index.js'

export default {
  install(app) {

    // Getting current z-index
    app.config.globalProperties.$zIndex = () => store.getters.zIndex;

    // Getting the next/new z-index and also increment the value in the store
    app.config.globalProperties.$zTop = () => {
      // store.commit('incrementZIndex');
      return store.getters.zIndex + 1;
    };
  }
};

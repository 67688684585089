
export default {
  state() {
    return {
      zIndex: 1000
    }
  },
  mutations: {
    incrementZIndex(state) {
      state.zIndex++;
    }
  },
  // getters: {
  //   zIndex: (state) => { state.zIndex },
  //   zTop: (state) => { state.zIndex++ }
  // }
  getters: {
    zIndex(state) {
      return state.zIndex;
    },
    zTop(state) {
      return state.zIndex++;
    }
  }
}

import allCountries from '@/data/dial_codes';

export default {
  install(app) {
    app.config.globalProperties.$parsePhoneNumber = (phoneNumber) => {
      const countryData = allCountries.find(
        country => phoneNumber.startsWith(`+${country[2]}`)
      );

      if (countryData) {
        const dialCode = `+${countryData[2]}`;
        const country = countryData[1];
        const number = phoneNumber.replace(`+${countryData[2]}`, '');

        return {
          dialCode,
          country,
          number,
        };
      } else {
        return {
          dialCode: null,
          country: null,
          number: null,
        };
      }
    };
  }
};
